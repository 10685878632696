/*路由设置文件*/
const routers = [
    {
        path: '/reward',
        name: 'Reward',
        meta:{index:1,title: '推荐有奖'},
        component: () => import('../views/push/reward.vue')
    },
    {
        path: '/notice',
        name: 'Notice',
        meta:{index:1,title: '面试通知'},
        component: () => import('../views/push/notice.vue')
    },
    {
        path: '/share',
        name: 'Share',
        meta:{index:1,title: '招聘'},
        component: () => import('../views/push/share.vue')
    },
    {
        path: '/recruit',
        name: 'Recruit',
        meta:{index:1,title: '招聘列表'},
        component: () => import('../views/push/recruit.vue')
    },
    {
        path: '/submit',
        name: 'Submit',
        meta:{index:1,title: '提交简历'},
        component: () => import('../views/push/submit.vue')
    },
    {
        path: '/show',
        name: 'Show',
        meta:{index:1,title: '招聘详情'},
        component: () => import('../views/push/show.vue')
    },
    {
        path: '/success',
        name: 'Success',
        meta:{index:1,title: '招聘详情'},
        component: () => import('../views/push/success.vue')
    },
    {
        path: '/appShare',
        name: 'AppShare',
        meta:{index:1,title: '人康国际'},
        component: () => import('../views/app/appShare.vue')
    },
    {
        path: '/agreement',
        name: 'agreement',
        meta:{index:1,title: '隐私协议'},
        component: () => import('../views/app/agreement.vue')
    },
    {
        path: '/userAgreement',
        name: 'userAgreement',
        meta:{index:1,title: '用户隐私协议'},
        component: () => import('../views/app/userAgreement.vue')
    },
    {
        path: '/Att',
        name: 'Att',
        meta:{index:1,title: '考勤条'},
        component: () => import('../views/att/Att.vue')
    },
    {
        path: '/performance',
        name: 'performanceIndex',
        meta:{index:1,title: '市场人力绩效考核'},
        component: () => import('../views/performance/index.vue')
    },
    {
        path: '/addPref',
        name: 'addPref',
        meta:{index:1,title: '市场人力绩效考核'},
        component: () => import('../views/performance/addPref.vue')
    },
    {
        path: '/prefList',
        name: 'prefList',
        meta:{index:1,title: '市场人力绩效考核'},
        component: () => import('../views/performance/list.vue')
    },
    {
        path: '/leaderIndex',
        name: 'leaderIndex',
        meta:{index:1,title: '市场人力绩效考核'},
        component: () => import('../views/performance/leaderIndex.vue')
    },
    {
        path: '/approval',
        name: 'approval',
        meta:{index:1,title: '市场人力绩效考核'},
        component: () => import('../views/performance/approval.vue')
    },
    {
        path: '/appIndex',
        name: 'appIndex',
        meta:{index:1,title: '市场人力绩效考核'},
        component: () => import('../views/performance/appIndex.vue')
    },
    {
        path: '/selfList',
        name: 'selfList',
        meta:{index:1,title: '市场人力绩效考核'},
        component: () => import('../views/performance/selfList.vue')
    },
    {
        path: '/showIndex',
        name: 'showIndex',
        meta:{index:1,title: '市场人力绩效考核'},
        component: () => import('../views/performance/showIndex.vue')
    },
    {
        path: '/showApproval',
        name: 'showApproval',
        meta:{index:1,title: '市场人力绩效考核'},
        component: () => import('../views/performance/showApproval.vue')
    },
    {
        path: '/perfLogin',
        name: 'perfLogin',
        meta:{index:1,title: '市场人力绩效考核'},
        component: () => import('../views/performance/perfLogin.vue')
    },
    {
    path: '/',
        component: () => import('../views/push/reward.vue')
    },
]
export default routers
