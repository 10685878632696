import Vue from 'vue'
import VueRouter from 'vue-router'
import routers from './router/router'
import App from './App.vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
Vue.use(VueAxios, axios)
import "vue-virtual-scroller/dist/vue-virtual-scroller.css";
import VueVirtualScroller from "vue-virtual-scroller";
Vue.use(VueVirtualScroller);
/*引入 vant 组件开始*/
import { NavBar } from 'vant';
Vue.use(NavBar);

import { Icon } from 'vant';
Vue.use(Icon);

import { Search } from 'vant';
Vue.use(Search);

import { List } from 'vant';
Vue.use(List);

import { Cell, CellGroup } from 'vant';
Vue.use(Cell);
Vue.use(CellGroup);

import { Field } from 'vant';
Vue.use(Field);

import { ActionSheet } from 'vant';
Vue.use(ActionSheet);

import { RadioGroup, Radio } from 'vant';
Vue.use(Radio);
Vue.use(RadioGroup);

import { Divider } from 'vant';
Vue.use(Divider);

import { Stepper } from 'vant';
Vue.use(Stepper);

import { Badge } from 'vant';
Vue.use(Badge);

import { DatetimePicker } from 'vant';
Vue.use(DatetimePicker);

import { Toast } from 'vant';
Vue.use(Toast);

import { Popup } from 'vant';
Vue.use(Popup);

import { Dialog } from 'vant';
Vue.use(Dialog);

import { Switch } from 'vant';
Vue.use(Switch);

import { Image} from 'vant';
Vue.use(Image);

import { Cascader } from 'vant';
Vue.use(Cascader);

import { Picker } from 'vant';
Vue.use(Picker);

import { Calendar } from 'vant';
Vue.use(Calendar);

import { Uploader } from 'vant';
Vue.use(Uploader);

import { Overlay } from 'vant';
Vue.use(Overlay);

import { Grid, GridItem } from 'vant';
Vue.use(Grid);
Vue.use(GridItem);

import { Step, Steps } from 'vant';
Vue.use(Step);
Vue.use(Steps);

import { Pagination } from 'vant';
Vue.use(Pagination);

import { DropdownMenu, DropdownItem } from 'vant';
Vue.use(DropdownMenu);
Vue.use(DropdownItem);
/*引入 vant 组件结束*/

Vue.use(VueRouter)
Vue.config.productionTip = false
const router = new VueRouter({
  mode: 'history',
  base: '/',//部署的子路径
  routes: routers
})
router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    document.title = to.meta.title
  }
  next()
  // 判断是不是人事绩效考核
 /* const perfPath = ['performance','addPref','prefList','leaderIndex','approval','appIndex','selfList','showIndex']
  if (perfPath.indexOf(to.name) !== -1 && !sessionStorage.getItem("token")){
    let url = location.href;
    let theRequest = new Object();
    if (url.indexOf('?') != -1) {
      let str = url.split('?')[1];
      let strs = str.split('&');
      for (let i = 0; i < strs.length; i++) {
        theRequest[strs[i].split('=')[0]] = strs[i].split('=')[1];
      }
    }
    console.log(theRequest)
    let query = theRequest
    // 记录打开的是那个页面，登录成功后再跳回去
    sessionStorage.setItem("perfPath", location.pathname.substr(1))
    sessionStorage.setItem("perfQuery", JSON.stringify(query))
    next({ name: 'perfLogin' })
  } else {
    next()
  }*/
})
new Vue({
  el: '#app',
  router,
  render: h => h(App),
}).$mount('#app')
